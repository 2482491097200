<template>
  <button
    :class="{
      'primary-medium-button': true,
      'primary-medium-button-alert': alert,
    }"
  >
    <div class="flex gap-2 justify-between items-center">
      <span
        v-if="icon != null && iconOnLeft"
        :class="{
          'icon text-[18px]': true,
          [icon]: true,
        }"
      >
      </span>
      <span v-if="label != null" class="l2">{{ label }}</span>
      <span
        v-if="icon != null && !iconOnLeft"
        :class="{
          'icon text-[18px]': true,
          [icon]: true,
        }"
      >
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
    },
    iconOnLeft: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>
.primary-medium-button {
  @apply px-4 h-10 bg-primaryV2 text-surface-10 rounded-full cursor-pointer flex justify-center items-center;
}

.primary-medium-button * {
  @apply text-surface-10;
}

.primary-medium-button:hover {
  @apply bg-primary-60;
}

.primary-medium-button:active {
  @apply bg-primary-60;
}

.primary-medium-button:disabled {
  @apply bg-neutral-20 text-neutral-40 cursor-not-allowed;
}

.primary-medium-button-alert {
  @apply bg-system-alert-base;
}

.primary-medium-button-alert:hover {
  @apply bg-system-alert-60;
}
.primary-medium-button-alert:active {
  @apply bg-system-alert-60;
}
</style>
