import { defineStore } from "pinia";
import Tracking from "~/utils/tracking";
import type { InsightsProfile } from "~/model/user";
import { getInsightProfile } from "~/helpers/api/profile";
import type { Sponsor } from "~/model/sponsor";
import { useAuthStore } from "../auth";

export const useUserStore = defineStore("user", {
  state: () => ({
    isLoading: false,
    insightsProfile: null as InsightsProfile,
  }),
  getters: {
    sponsors(): Sponsor[] {
      return this.insightsProfile?.sponsors;
    },
    getSponsorById: (state) => (id: string) => {
      return state.insightsProfile?.sponsors.find((s) => s.id === id);
    },
  },
  actions: {
    async loadInsightsProfile() {
      if (this.insightsProfile) {
        return;
      }

      this.isLoading = true;
      try {
        const response = await getInsightProfile();
        if (!response.sponsors && response.sponsors.length === 0) {
          const authStore = useAuthStore();
          await authStore.logoutApiCall();
        } else {
          this.insightsProfile = response;
          const tracking = Tracking.getInstance();
          tracking.setUser(this.insightsProfile);
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async refreshInsightsProfile() {
      this.isLoading = true;
      try {
        const response = await getInsightProfile();
        if (!response.sponsors && response.sponsors.length === 0) {
          const authStore = useAuthStore();
          await authStore.logoutApiCall();
        } else {
          this.insightsProfile = response;
        }
      } catch (error) {}
      this.isLoading = false;
    },
  },
});
